import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Contact" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <p>
            We are available for contact by filling out the form bellow or
            message us on:
            <li>Discord: @e11i0t23#7272</li>
            <li>Geekhack: e11i0t23</li>
            <li>Reddit: e11i0t23</li>
            <li>Instagram: @epkbdesign</li>
            <li>Email: elliot@elliot-powell.com</li>
          </p>
          <h2 id="forms">Contact Form</h2>
          <form
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <label>
              Name
              <input type="text" name="name" id="name" />
            </label>
            <label>
              Email
              <input type="email" name="email" id="email" />
            </label>
            <label>
              Subject
              <select name="subject" id="subject">
                <option value="PCB Design">PCB Design</option>
                <option value="Case Design">Case Design</option>
                <option value="Design Package">Design Package</option>
                <option value="Other">Other</option>
              </select>
            </label>
            <label>
              Message
              <textarea name="message" id="message" rows="5" />
            </label>
            <button type="submit">Send</button>
            <input type="reset" value="Clear" />
          </form>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactsPage location={props.location} data={data} {...props} />
    )}
  />
)
